import { sanLocalStorage } from '@js/includes/storageFactory.ts'

export const headerSearchHistoryMaxLength = 10

const updateStorage = (newContent: HeaderSearchHistoryItem[]) => {
  // eslint-disable-next-line no-console
  if (import.meta.env.DEV) console.debug('Search storage', newContent)

  sanLocalStorage.setItem('headerSearchHistory', JSON.stringify(newContent))
}

export const getHeaderSearchHistory: () => HeaderSearchHistoryItem[] = () => {
  const history = sanLocalStorage.getItem('headerSearchHistory')
  if (history) {
    try {
      return JSON.parse(history)
    } catch {
      return []
    }
  }

  return []
}

const removeFromHistoryArray = (searchString: string, history = getHeaderSearchHistory()) => {
  const indexOfSearchItem = history.findIndex((item) => {
    return item.string === searchString
  })

  if (indexOfSearchItem !== -1) {
    history.splice(indexOfSearchItem, 1)
  }

  return history
}

export const removeFromHeaderSearchHistory = (searchString: string) => {
  const history = removeFromHistoryArray(searchString)
  updateStorage(history)
  return history
}

export const addToHeaderSearchHistory = (item: HeaderSearchHistoryItem) => {
  if (item.string.length < 3) {
    return
  }

  let history = getHeaderSearchHistory()
  history = removeFromHistoryArray(item.string, history)

  history.push(item)

  // Если история больше historyMaxLength элементов, то удаляем лишний элемент
  if (history.length > headerSearchHistoryMaxLength) {
    history.splice(0, 1)
  }

  updateStorage(history)
}

export type HeaderSearchHistoryItem = {
  string: string
  href?: string
  type?: 'generic' | 'specific'
}
