import { addToCart } from '@js/api/cart/addToCart.ts'
import { generateOrderButton } from '@js/components/Buttons/generateOrderButton.ts'
import { showAddToCartModal } from '@js/components/modals/added-to-cart/initAddedToCart.ts'
import { openQuickView } from '@js/components/modals/QuickView/quickview.ts'
import { saveCardNeighbors } from '@js/components/product-card/cardNeighbors.ts'
import { sprites } from '@js/includes/_generatedVariables.ts'
import { cartPurchaseIDs } from '@js/stores/cartData.ts'
import { favouritesStore, toggleFavourite } from '@js/stores/favouritesStore.ts'
import { get } from 'svelte/store'

const processOrderClick = (product: HTMLElement, clickedOrderButton: HTMLElement, wrapperElement: HTMLElement) => {
  if (!product.dataset.variantId || clickedOrderButton.classList.contains('disabled')) {
    return
  }

  if (clickedOrderButton.classList.contains('btn-order--quickview') && product.dataset.productId) {
    saveCardNeighbors(product)
    openQuickView(product.dataset.productId, wrapperElement, 'buy')
  } else {
    const quantityInput = product.querySelector<HTMLInputElement>('.product-quantity__input')
    const amount = quantityInput ? Number.parseInt(quantityInput.value, 10) : 1
    clickedOrderButton.classList.add('disabled')
    addToCart({
      variant: product.dataset.variantId,
      amount,
    }).then(() => {
      clickedOrderButton.classList.remove('disabled')
      showAddToCartModal({
        productID: product.dataset.variantId,
        optionIDs: new Set(),
        amount,
        areaStep: product.dataset.areaStep ? Number.parseFloat(product.dataset.areaStep) : null,
      })
    })
  }
}

export const productCardProcessClick = (wrapperElement: HTMLElement) => {
  wrapperElement.addEventListener('click', (event) => {
    if (event.target instanceof Element) {
      const product = event.target.closest<HTMLElement>('.product-card')

      // Order button click
      const clickedOrderButton = event.target.closest<HTMLElement>('.btn-order:not(.btn-order--to-cart)')
      if (product && clickedOrderButton) {
        event.preventDefault()
        processOrderClick(product, clickedOrderButton, wrapperElement)
      }

      // QuickView click
      if (product?.dataset.productId && event.target.closest('.product-card__quickview')) {
        saveCardNeighbors(product)
        openQuickView(product.dataset.productId, wrapperElement)
      }

      // Favourites click
      if (product?.dataset.productId && event.target.closest('.product-card__favorite')) {
        toggleFavourite(product.dataset.productId)
      }
    }
  })
}

const removeInCartProductStatus = (product: Element | HTMLElement) => {
  if (product.classList.contains('product-card--in-cart')) {
    product.classList.remove('product-card--in-cart')
    const inCartLabel = product.querySelector('.product-card__in-cart-overlay')
    inCartLabel?.remove()

    const orderButton = product.querySelector('.btn-order')
    if (orderButton && !product.classList.contains('product-card--dynamic-amount')) {
      orderButton.outerHTML = generateOrderButton({ inCart: false, class: 'mt-3' })
    }
  }
}

const addInCartProductStatus = (product: Element | HTMLElement) => {
  if (!product.classList.contains('product-card--in-cart')) {
    product.classList.add('product-card--in-cart')

    const staticCard = product.classList.contains('product-card--static')

    // У статических карточек кнопка "уже в корзине" и так видна
    if (!staticCard) {
      const inCartLabel = document.createElement('div')
      inCartLabel.className = 'product-card__in-cart-overlay'
      // language=HTML
      inCartLabel.innerHTML = `
        <div class="product-card__in-cart-overlay-text">
          <svg width="24" height="24" aria-hidden="true">
            <use xlink:href="${sprites.svgsprite2}#cart_ok"></use>
          </svg>
          <span>Уже в корзине</span>
        </div>
      `
      const imageWrap = product.querySelector('.product-card__image-wrap')
      imageWrap?.appendChild(inCartLabel)
    }

    const orderButton = product.querySelector('.btn-order')
    if (orderButton && !product.classList.contains('product-card--dynamic-amount')) {
      orderButton.outerHTML = generateOrderButton({
        inCart: true,
        class: 'mt-3',
        text: staticCard ? 'Уже в корзине' : undefined,
      })
    }
  }
}

const updateProductsStatus = (wrapperElement: HTMLElement, data?: Set<string>) => {
  const purchaseIDs = data ?? get(cartPurchaseIDs)
  const favourites = get(favouritesStore)

  const productsInSwiper = wrapperElement.querySelectorAll<HTMLElement>('.product-card')
  for (const product of productsInSwiper) {
    // Статус "в корзине"
    const variationID = product.dataset.variantId
    if (variationID) {
      if (purchaseIDs.has(variationID)) {
        addInCartProductStatus(product)
      } else {
        removeInCartProductStatus(product)
      }
    }

    // Избранное
    const productID = product.dataset.productId
    if (productID) {
      const favouriteButton = product.querySelector('.product-card__favorite')
      const iconUseElement = favouriteButton?.querySelector('.product-card__favorite use')

      if (favouriteButton && iconUseElement) {
        if (favourites.has(productID) && !favouriteButton.classList.contains('active')) {
          favouriteButton.classList.add('active')
          iconUseElement.setAttribute('xlink:href', `${sprites.svgsprite2}#icon_favourite-enabled`)
        } else if (!favourites.has(productID) && favouriteButton.classList.contains('active')) {
          favouriteButton.classList.remove('active')
          iconUseElement.setAttribute('xlink:href', `${sprites.svgsprite2}#icon_favourite-disabled`)
        }
      }
    }
  }
}

export const productCardSubscribeToUpdates = (wrapperElement: HTMLElement) => {
  let firstTime = true

  cartPurchaseIDs.subscribe((data) => {
    updateProductsStatus(wrapperElement, data)
  })

  favouritesStore.subscribe(() => {
    // subscribe запускает функцию сразу, тут два subscribe подряд, поэтому используем проверку firstTime
    if (firstTime) {
      firstTime = false
      return
    }

    updateProductsStatus(wrapperElement)
  })
}
