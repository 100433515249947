import type { CartData, CartRequestData } from '@js/api/cart/types.d.ts'
import { notiStore } from '@js/components/Notifications/notification-store.ts'
import { cartData } from '@js/stores/cartData.ts'
import { cartUpdating } from '@js/stores/globalStores.ts'
import { captureException } from '@sentry/svelte'
import wretch, { type ConfiguredMiddleware } from 'wretch'
import { retry } from 'wretch/middlewares'

const processCartData = (data: CartRequestData) => {
  const output: CartData = {
    ...data,
    cart: {
      ...data.cart,
      purchases: new Map(data.cart?.purchases ? Object.entries(data.cart.purchases) : []),
    },
  }

  return output
}

export const makeCartRequest = async (
  parameters_: Record<string, string>,
  errorMessage: null | string = 'Не удалось обновить корзину',
) => {
  const parameters = new URLSearchParams(parameters_)

  cartUpdating.set(true)

  const wretchMiddlewares: ConfiguredMiddleware[] = [
    retry({
      maxAttempts: 3,
    }),
  ]
  let localWretch = wretch('/cart/?' + parameters.toString()).middlewares(wretchMiddlewares)

  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.debug('Параметры запроса:', parameters.toString())

    const { delay } = await import('wretch/middlewares')

    localWretch = localWretch
      .url(
        parameters_.action === 'remove_variant'
          ? '/js/__development-api/cart--minus1.json'
          : '/js/__development-api/cart.json',
        true,
      )
      .middlewares([...wretchMiddlewares, delay(1_000)])
  }

  const data = await localWretch
    .get()
    .json<CartRequestData>()
    .catch((error) => {
      captureException(error, error.response ?? null)
      if (errorMessage) {
        notiStore.push('error', { timeout: false, props: { content: errorMessage } })
      }

      return undefined
    })

  if (data) {
    cartData.set(processCartData(data))
  }

  cartUpdating.set(false)

  return Boolean(data)
}
