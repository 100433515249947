<svelte:options
  customElement={{
    tag: 'svg-icon',
    shadow: 'none',
    props: {
      class: { attribute: 'icon-class' },
      ariaHidden: { attribute: 'aria-hidden' },
    },
  }}
/>

<script lang="ts">
  import { sprites } from '@js/includes/_generatedVariables.ts'

  export let title = ''
  export let ariaHidden = title === ''
  export let iconID: string
  export let width: number | string = 24
  export let height: number | string = 24
  export let spriteName = 'svgsprite2'

  height = typeof width === 'string' ? Number.parseInt(width, 10) : width

  let className = ''
  export { className as class }
</script>

<svg
  {width}
  {height}
  aria-hidden={ariaHidden === false ? null : true}
  role={ariaHidden ? null : 'img'}
  class={className === '' ? null : className}
>
  {#if title}
    <title>{title}</title>
  {/if}
  <use xlink:href="{sprites[spriteName]}#{iconID}"></use>
</svg>
