// import { initProductCardDynamicAmount } from '@js/components/product-card/initProductCardDynamicAmount.ts'
import { initCardCollectionItems } from '@js/components/product-card/card-collection-items/initCardCollectionItems.ts'
import { initEditableNote } from '@js/components/product-card/editable/editableNote.ts'
import {
  productCardProcessClick,
  productCardSubscribeToUpdates,
} from '@js/components/product-card/product-card-functions'
import { addProductCardGallerySwipers } from '@js/components/Swiper/swiper--product-card-gallery'
import { debounce } from '@js/includes/functions'
import { mobileDesktopExecutor } from '@js/site-functions/mobileDesktopExecutor.ts'
import { userData } from '@js/stores/userData.ts'
import { get } from 'svelte/store'
import { type Swiper } from 'swiper'

const desktopResolution = window.matchMedia('(min-width: 992px)')
const userID = get(userData).id

const updateCardsParent = (cardsParent: HTMLElement, thisCard?: HTMLElement) => {
  const activeCards = cardsParent.querySelectorAll<HTMLElement>('.product-card.active')

  if (activeCards.length > 0) {
    cardsParent.classList.add('has-card-active')

    if (activeCards.length > 1) {
      for (const card of activeCards) {
        if (card !== thisCard) {
          toggleCardState(card, cardsParent, false)
        }
      }
    }
  } else {
    cardsParent.classList.remove('has-card-active')
  }
}

// eslint-disable-next-line func-style
function toggleCardState(card: HTMLElement, cardsParent: HTMLElement, active: boolean) {
  const cardBody = card.querySelector<HTMLElement>('.product-card__body')
  const swiper = card.querySelector<{ swiper?: Swiper } & HTMLElement>(
    '.swiper-wrap--product-card-gallery > .swiper',
  )?.swiper

  if (active) {
    cardBody?.style.removeProperty('min-height')

    // Небольшая задержка, чтобы браузер успел обработать изменения
    requestAnimationFrame(() => {
      // Прописываем жёстко, чтобы избежать сдвигов
      card.style.height = card.getBoundingClientRect().height + 'px'
      if (cardBody) {
        cardBody.style.minHeight = cardBody.getBoundingClientRect().height + 'px'
      }

      card.classList.add('active')
      card.classList.remove('transitioning')
      updateCardsParent(cardsParent, card)
    })
  } else if (card.classList.contains('active')) {
    card.classList.remove('active')
    card.classList.add('transitioning')
    // animation delay 0.2
    setTimeout(() => {
      // Возможно повторное наведение за это время
      if (!card.classList.contains('active')) {
        card.classList.remove('transitioning')
        card.style.removeProperty('height')
        if (cardBody) {
          cardBody.style.removeProperty('min-height')
        }

        // Сбрасываем swiper до первого фрейма
        if (swiper) {
          swiper.slideTo(0)
        }

        updateCardsParent(cardsParent, card)
      }
    }, 250)
  }
}

// Только на десктопе
export const checkCardState = (card: HTMLElement, cardsParent: HTMLElement) => {
  if (!desktopResolution.matches || cardsParent.classList.contains('draggable-container--is-dragging')) {
    return
  }

  toggleCardState(card, cardsParent, card.matches(':hover, :focus-within'))
}

const inCardProjectsDataEdit = async (card: HTMLElement) => {
  // Функционал проектов для авторизованных юзеров
  if (userID) {
    if (card.querySelector('.product-card-notes__tag > .btn-close')) {
      const { initRemovableTags } = await import('@js/components/product-card/editable/removableTags.ts')
      mobileDesktopExecutor({
        onDesktop: () => {
          return initRemovableTags(card)
        },
      })
    }

    if (card.querySelector('.product-card-notes__project-comment')) {
      const { initEditableProjectComment } = await import(
        '@js/components/product-card/editable/editableProjectComment.ts'
      )
      mobileDesktopExecutor({
        onDesktop: () => {
          return initEditableProjectComment(card)
        },
      })
    }
  }
}

const initNewCards = async (cardsParent: HTMLElement) => {
  addProductCardGallerySwipers(cardsParent)

  const cardList = cardsParent.querySelectorAll<HTMLElement>('.product-card')

  for (const card of cardList) {
    const cardBody = card.querySelector<HTMLElement>('.product-card__body')
    // Пропускаем ранее инициализированные и сломанные
    if (card.classList.contains('product-card--initialized') || !cardBody) {
      continue
    }

    await initCardCollectionItems(card)

    card.addEventListener('mouseenter', () => {
      // Hover intent
      setTimeout(() => {
        checkCardState(card, cardsParent)
      }, 200)
    })

    const eventTypes = ['mouseleave', 'focusin', 'focusout', 'update-contents']
    for (const eventType of eventTypes) {
      card.addEventListener(eventType, () => {
        checkCardState(card, cardsParent)
      })
    }

    card.classList.add('product-card--initialized')

    // if (card.classList.contains('product-card--dynamic-amount')) {
    //   initProductCardDynamicAmount(card)
    // }

    mobileDesktopExecutor({
      onDesktop: () => {
        return initEditableNote(card)
      },
    })

    await inCardProjectsDataEdit(card)
  }
}

// Сбрасываем прописанную высоту карточек при ресайзе окна
const resetCardHeight = (cardsParent: HTMLElement) => {
  const cardList = cardsParent.querySelectorAll<HTMLElement>('.product-card[style]')
  for (const card of cardList) {
    if (!card.matches('.active, .transitioning')) {
      card.style.removeProperty('height')

      const cardBody = card.querySelector<HTMLElement>('.product-card__body')
      if (cardBody) {
        cardBody.style.removeProperty('min-height')
      }
    }
  }
}

/**
 * Initializes the product card dynamic functions.
 * @param parent
 * @param {boolean} [newWrapper] - Indicates whether wrapper is new and more event tracking should be added. Defaults to true.
 */
export const initProductCardHelper = async (parent?: HTMLElement | null, newWrapper: boolean = true) => {
  const cardsParents: HTMLElement[] = parent
    ? [parent]
    : Array.from(document.querySelectorAll<HTMLElement>('.page-catalog__grid, .product-card-grid'))

  if (cardsParents.length === 0) {
    return
  }

  for (const cardParent of cardsParents) {
    if (newWrapper) {
      productCardProcessClick(cardParent)
      productCardSubscribeToUpdates(cardParent)
    }

    await initNewCards(cardParent)

    window.addEventListener(
      'resize',
      debounce(() => {
        resetCardHeight(cardParent)
      }),
    )
  }
}
