import type QuickView from '@js/components/modals/QuickView/QuickView.svelte'
import { createClassComponent } from 'svelte/legacy'

let loadedQuickView: ReturnType<typeof QuickView> | undefined

export const openQuickView = (productID: string, wrapperElement: HTMLElement, mode: 'buy' | 'default' = 'default') => {
  const connectedProducts = new Map<number, string>()
  let currentCardIndex = 0
  const closeProducts = wrapperElement.querySelectorAll<HTMLElement>('.product-card, .product-card-mini__product-link')
  for (const [index, closeProductCard] of closeProducts.entries()) {
    const cardProductID = closeProductCard.dataset.productId
    if (cardProductID) {
      connectedProducts.set(index, cardProductID)
    }

    if (cardProductID === productID) {
      currentCardIndex = index
    }
  }

  if (loadedQuickView) {
    loadedQuickView.$set({
      show: true,
      productID,
      connectedProducts,
      currentCardIndex,
      mode,
    })
  } else {
    // eslint-disable-next-line promise/prefer-await-to-then
    import('@js/components/modals/QuickView/QuickView.svelte').then((imported) => {
      loadedQuickView = createClassComponent({
        component: imported.default,
        target: document.body,
        props: {
          show: true,
          productID,
          connectedProducts,
          currentCardIndex,
          mode,
        },
      })
      loadedQuickView.$set({
        onHideExport: () => {
          loadedQuickView?.$set({
            show: false,
            productID: undefined,
            connectedProducts: new Map(),
            mode: undefined,
            currentCardIndex: undefined,
          })
        },
      })
    })
  }
}
