import scrollIntoView from 'scroll-into-view-if-needed'

const toggleSubmitButton = (form: HTMLFormElement) => {
  const fields = form.querySelectorAll<HTMLInputElement>('input, select, textarea')
  const submitButton = form.querySelector<HTMLButtonElement>('button[type="submit"]')

  if (fields.length === 0 || !submitButton) {
    return
  }

  // Поначалу кнопка выключена
  submitButton.disabled = true

  for (const field of fields) {
    // На случай умолчаний, браузерного автодополнения и т.п.
    if (field.value !== '') {
      submitButton.disabled = false
      break
    }

    field.addEventListener(
      'change',
      () => {
        if (form.checkValidity()) {
          submitButton.disabled = false
        }
      },
      { once: true },
    )

    field.addEventListener(
      'input',
      () => {
        submitButton.disabled = false
      },
      { once: true },
    )
  }
}

export const initBootstrapFormValidation = () => {
  const forms = document.querySelectorAll<HTMLFormElement>('form.needs-validation')

  for (const form of forms) {
    form.addEventListener(
      'submit',
      (event) => {
        if (!form.checkValidity()) {
          event.preventDefault()
          event.stopPropagation()

          const firstInvalid = form.querySelector<HTMLInputElement>('input:invalid')
          if (firstInvalid) {
            scrollIntoView(firstInvalid, {
              block: 'center',
              scrollMode: 'if-needed',
            })
          }
        }

        form.classList.add('was-validated')
      },
      false,
    )

    toggleSubmitButton(form)
  }
}
