import { debounce } from '@js/includes/functions.ts'
import { isMobile } from '@js/includes/isMobile'
import { sanOnScroll } from '@js/site-functions/sanOnScroll.ts'

// Same as in page-header_bottom.scss
const transitionDelay = 0

const setHeaderHeight = (headerBottom: HTMLElement) => {
  document.documentElement.style.setProperty('--header-height', headerBottom.clientHeight + 'px')
}

const setDetachedHeaderProps = (headerBottom: HTMLElement) => {
  document.documentElement.classList.add('state--detached-header')
  headerBottom.classList.add('page-header__bottom--detached')
  setTimeout(() => {
    setHeaderHeight(headerBottom)
  }, transitionDelay)
}

const toggleFixedHeader = (headerTop: HTMLElement, headerBottom: HTMLElement) => {
  // На мобиле он всегда static
  if (isMobile()) {
    setHeaderHeight(headerBottom)
    // setDetachedHeaderProps(headerBottom)
    return
  }

  // 24 - примерная высота навбара, но в целом от балды 🤷‍♂️
  if (window.scrollY > headerTop.clientHeight + 24) {
    setDetachedHeaderProps(headerBottom)
  } else {
    document.documentElement.classList.remove('state--detached-header')
    headerBottom.classList.remove('page-header__bottom--detached')
    setTimeout(() => {
      const height = headerTop.clientHeight + headerBottom.clientHeight + 'px'
      document.documentElement.style.setProperty('--header-height', height)
      if (window.scrollY === 0) {
        document.documentElement.style.setProperty('--header-full-height', height)
      }
    }, transitionDelay)
  }
}

const processScroll = (headerTop: HTMLElement, headerBottom: HTMLElement) => {
  let lastKnownScrollPosition = window.scrollY
  let scrollDirection: 'bottom' | 'top' = 'top'

  sanOnScroll({
    functionOnTimer: () => {
      if (lastKnownScrollPosition < window.scrollY) {
        scrollDirection = 'bottom'
      } else if (lastKnownScrollPosition > window.scrollY) {
        scrollDirection = 'top'
      }

      lastKnownScrollPosition = window.scrollY
    },
    timer: 120,
    functionAnimation: () => {
      if (!isMobile()) {
        toggleFixedHeader(headerTop, headerBottom)
      }

      // На мобильном прячем при скролле вниз и если не у верхней границы окна
      if (isMobile() && scrollDirection === 'bottom' && window.scrollY > headerBottom.clientHeight) {
        headerBottom.classList.add('hide')
        headerBottom.dispatchEvent(new Event('hidden'))
      } else {
        headerBottom.classList.remove('hide')
        headerBottom.dispatchEvent(new Event('shown'))
      }
    },
  })
}

export const initFixedHeader = () => {
  const headerTop = document.querySelector<HTMLElement>('.page-header__top')
  const headerBottom = document.querySelector<HTMLElement>('.page-header__bottom')
  if (!headerTop || !headerBottom) {
    return
  }

  // На странице товара отключаем… по большей части
  const substrate = document.querySelector<HTMLElement>('.page-substrate')
  if (substrate?.classList.contains('page--product')) {
    document.documentElement.style.setProperty(
      '--header-height',
      headerTop.clientHeight + headerBottom.clientHeight + 'px',
    )

    window.addEventListener(
      'resize',
      debounce(() => {
        document.documentElement.style.setProperty(
          '--header-height',
          headerTop.clientHeight + headerBottom.clientHeight + 'px',
        )
      }, transitionDelay),
    )

    return
  }

  toggleFixedHeader(headerTop, headerBottom)

  window.addEventListener(
    'resize',
    debounce(() => {
      toggleFixedHeader(headerTop, headerBottom)

      if (!isMobile()) {
        headerBottom.classList.remove('hide')
        headerBottom.dispatchEvent(new Event('shown'))
      }
    }),
  )

  processScroll(headerTop, headerBottom)
}
