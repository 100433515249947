import { captureException } from '@sentry/svelte'
import Cookies from 'js-cookie'
import { mount } from 'svelte'

export const initCookieWarning = async () => {
  // cookie уже есть
  if (Cookies.get('CookieInfoScript')) {
    return
  }

  try {
    const cookieWarningImport = await import('@js/components/CookieWarning/CookieWarning.svelte')

    mount(cookieWarningImport.default, {
      target: document.body,
    })
  } catch (error: unknown) {
    captureException(error)
  }
}
