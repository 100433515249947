<script lang="ts">
  import {
    addToHeaderSearchHistory,
    getHeaderSearchHistory,
    type HeaderSearchHistoryItem,
    headerSearchHistoryMaxLength,
    removeFromHeaderSearchHistory,
  } from '@js/components/HeaderSearch/headerSearchHistory.ts'
  import SvgIcon from '@js/components/SvgIcon.svelte'
  import { onMount } from 'svelte'

  export let searchValue = ''
  export let searchActive = false
  export let searchContainer: HTMLElement | undefined
  export let searchResultsClass: string | undefined = undefined
  export let isMobile = false

  let searchHistory: HeaderSearchHistoryItem[] = []
  let showHistory = false
  let searchField: HTMLElement | null = null

  const processSearchHistory = (history: HeaderSearchHistoryItem[]) => {
    history.reverse()
    if (history.length > headerSearchHistoryMaxLength) {
      history.splice(headerSearchHistoryMaxLength - history.length)
    }

    return history
  }

  const addResultEventListeners = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    if (target.nodeName === 'A') {
      if (import.meta.env.DEV) event.preventDefault()
      const parentGroup = target.closest('.search-results-modal-result')
      if (parentGroup?.classList.contains('search-results-modal-result--show-all')) {
        addToHeaderSearchHistory({ string: searchValue })
      } else {
        addToHeaderSearchHistory({
          string: target.textContent?.trim() ?? searchValue,
          href: (target as HTMLLinkElement).href,
          type: 'specific',
        })
      }
    }
  }

  onMount(() => {
    searchHistory = processSearchHistory(getHeaderSearchHistory())
    searchField = searchContainer ? searchContainer.querySelector('#search-main') : null
  })

  $: {
    searchHistory = processSearchHistory(getHeaderSearchHistory())
    showHistory = searchActive && searchValue === '' && searchHistory.length > 0

    if (searchContainer) {
      // В мобильной версии блок с результатами не постоянно есть в HTML
      const resultsElement =
        (searchResultsClass ? searchContainer.querySelector<HTMLElement>(searchResultsClass) : searchContainer) ??
        searchContainer
      resultsElement.addEventListener('click', addResultEventListeners)
      if (showHistory) {
        searchContainer.classList.add('has-history')
        searchField = searchContainer.querySelector('#search-main')
      } else {
        searchContainer.classList.remove('has-history')
      }
    }
  }
</script>

{#if showHistory}
  <!-- svelte-ignore a11y_no_noninteractive_tabindex -->
  <ul
    tabindex="0"
    aria-label="История поиска"
    class="search-history list-unstyled"
    class:search-history--mobile={isMobile}
    class:search-history--desktop={!isMobile}
  >
    {#each searchHistory as searchHistoryItem}
      <li class="section--10 d-flex align-items-center gap-5" class:catalogMenu__interactive-btn={isMobile}>
        {#if searchHistoryItem.href}
          <a
            href={searchHistoryItem.href}
            class="search-history__item btn btn-link btn--with-icon overflow-hidden"
            class:search-history__item--specific={searchHistoryItem.type === 'specific'}
          >
            <SvgIcon iconID="history" width={14} class="btn__icon" />
            <span class="d-block text-overflow">{searchHistoryItem.string}</span>
          </a>
        {:else}
          <button
            type="button"
            class="search-history__item btn btn-link btn--with-icon overflow-hidden"
            on:click={() => {
              searchValue = searchHistoryItem.string
              if (searchField) {
                searchField.focus()
                searchField.dispatchEvent(new Event('input'))
              }
            }}
          >
            <SvgIcon iconID="history" width={14} class="btn__icon" />
            <span class="d-block text-overflow">{searchHistoryItem.string}</span>
          </button>
        {/if}
        <button
          type="button"
          class="btn btn-transparent opacity-50 p-2 lh-1 flex-shrink-0"
          title="Удалить «{searchHistoryItem.string}» из истории поиска"
          on:click={() => {
            searchHistory = removeFromHeaderSearchHistory(searchHistoryItem.string)
          }}
        >
          <SvgIcon iconID="controls--close" width="10" />
        </button>
      </li>
    {/each}
  </ul>
{/if}
