import HeaderSearch from '@js/components/HeaderSearch/HeaderSearch.svelte'
import { mount } from 'svelte'

export const initHeaderSearch = () => {
  const searchContainer = document.querySelector<HTMLElement>('.page-header__search')

  if (searchContainer) {
    searchContainer.innerHTML = ''

    mount(HeaderSearch, {
      target: searchContainer,
      props: {
        searchContainer,
      },
    })
  }
}
