import Modal from '@js/components/modals/Modal.svelte'
import { mobile } from '@js/stores/globalStores'
import { Popover, Tooltip } from 'bootstrap'
import { mount, unmount } from 'svelte'

let popoverTriggerList: HTMLElement[] = []
let elementCounter = 0

export const getPopoverSettings = (popoverTriggerElement: HTMLElement): Partial<Popover.Options> => {
  return {
    trigger: (popoverTriggerElement.dataset.bsTrigger as Popover.Options['trigger']) ?? 'click',
    html: true,
    popperConfig: (defaultBsPopperConfig) => {
      return {
        ...defaultBsPopperConfig,
        modifiers: [
          ...defaultBsPopperConfig.modifiers,
          {
            name: 'preventOverflow',
            options: {
              mainAxis: false, // true by default
            },
          },
        ],
      }
    },
    template:
      '<div class="popover" role="tooltip">' +
      '<div class="popover-arrow"></div>' +
      '<p class="popover-header h3 d-flex align-items-center justify-content-between"></p>' +
      '<div class="popover-body"></div>' +
      '</div>',
    title() {
      if (popoverTriggerElement.dataset.bsTitle) {
        return `<span class="text--half-border me-5">${popoverTriggerElement.dataset.bsTitle}</span><button type="button" class="btn-close btn-close--white" aria-label="Закрыть"></button>`
      }

      return ''
    },
  }
}

export const initPopover = (
  popoverTriggerElement: HTMLElement,
  popoverContent: Popover.Options['content'] = popoverTriggerElement.dataset.bsContent ?? '',
  show = false,
) => {
  let localPopoverContent = popoverContent
  const options = getPopoverSettings(popoverTriggerElement)
  if (!popoverContent) {
    const contentElement = popoverTriggerElement.nextElementSibling?.classList.contains('popover-content')
      ? popoverTriggerElement.nextElementSibling
      : null
    if (contentElement?.innerHTML) {
      localPopoverContent = contentElement.innerHTML
    }
  }

  options.content = localPopoverContent

  let newPopover: Popover | undefined
  let newModal: ReturnType<typeof Modal> | undefined
  const triggerDisableModal = popoverTriggerElement.dataset.mobileModal === 'false'

  const unsubscribe = mobile.subscribe((isMobile) => {
    // Если элемент пропал из DOM, отписываемся
    if (!popoverTriggerElement.isConnected) {
      unsubscribe()
      return
    }

    if (isMobile && !triggerDisableModal) {
      newPopover?.dispose()
      newModal = mount(Modal, {
        target: document.body,
        props: {
          id: `popover-modal-${elementCounter++}`,
          show,
          title: popoverTriggerElement.dataset.bsTitle ?? popoverTriggerElement.getAttribute('title') ?? '',
          modalDialogClass: 'modal-dialog--layered-multi',
          trigger: popoverTriggerElement,
          modalContent:
            typeof localPopoverContent === 'string'
              ? localPopoverContent
              : (localPopoverContent as HTMLElement).outerHTML,
        },
      })
    } else {
      if (newModal) {
        unmount(newModal)
      }

      newPopover = new Popover(popoverTriggerElement, options)
      if (show) {
        newPopover.show()
      }
    }
  })

  popoverTriggerElement.addEventListener('inserted.bs.popover', (event) => {
    // Спрятать остальные поповеры
    for (const loopTriggerElement of popoverTriggerList) {
      if (loopTriggerElement !== event.target) {
        Popover.getInstance(loopTriggerElement)?.hide()
      }
    }

    const popoverID = popoverTriggerElement.attributes.getNamedItem('aria-describedby')
    if (popoverID) {
      const closeButton = document.querySelector('#' + popoverID.value + ' .btn-close')
      closeButton?.addEventListener('click', () => {
        Popover.getInstance(popoverTriggerElement)?.hide()
      })
    }
  })

  return {
    modal: newModal,
    popover: newPopover,
  }
}

// Возвращает функцию, удаляющую инстансы Popover
export const initAdditionalPopovers = (parent: HTMLElement) => {
  const additionalPopoverTriggerList = parent.querySelectorAll<HTMLElement>('[data-bs-toggle="popover"]')
  for (const popoverTriggerElement of additionalPopoverTriggerList) {
    initPopover(popoverTriggerElement)
    popoverTriggerList.push(popoverTriggerElement)
  }

  return () => {
    for (const popoverTriggerElement of additionalPopoverTriggerList) {
      Popover.getInstance(popoverTriggerElement)?.dispose()
    }
  }
}

export const initPopovers = async () => {
  const myDefaultAllowList = Tooltip.Default.allowList
  myDefaultAllowList.button = []

  popoverTriggerList = Array.from(document.querySelectorAll<HTMLElement>('[data-bs-toggle="popover"]'))
  for (const popoverTriggerElement of popoverTriggerList) {
    // skip init on page load
    if (!popoverTriggerElement.dataset.filterId) {
      initPopover(popoverTriggerElement)
    }
  }

  document.addEventListener('click', (event) => {
    if (event.target) {
      const target = event.target as HTMLElement
      // Мде, это надо как-то зарефакторить…
      // Закрывать все поповеры при клике вне поповера, с исключениями
      if (
        !(
          target.classList.contains('popover') ||
          target.closest('.popover') ||
          target.classList.contains('product__info-btn--additional-info') ||
          target.closest('.product__info-btn--additional-info')
        ) ||
        // Кнопка внутри поповера, вызывающая модалку
        target.closest('.page-header__callback-button')
      ) {
        for (const popoverTriggerElement of popoverTriggerList) {
          Popover.getInstance(popoverTriggerElement)?.hide()
        }
      }
    }
  })
}
