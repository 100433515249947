<script lang="ts">
  import Loader from '@js/components/Loader.svelte'
  import { addModalDrag, removeModalDrag } from '@js/components/modals/addModalDrag.ts'
  import { sprites } from '@js/includes/_generatedVariables.ts'
  import { Modal } from 'bootstrap'
  import { createEventDispatcher, onMount, tick } from 'svelte'

  export let id: string
  export let title: string | null | undefined = ''

  export let modalElement: HTMLElement | undefined = undefined

  export let bsModal: Modal | null = null
  export let show = false
  // TODO: Баг https://github.com/sveltejs/svelte/issues/14161
  export let onHideExport = () => {}
  // Используется вместе с `conditional`
  let delayedShow = show
  // Уничтожается если не delayedShow
  export let conditional = false
  export let trigger: NodeListOf<HTMLElement> | HTMLElement | null = null
  export let size: 'sm' | 'md' | 'lg' | 'xl' = 'md'
  export let bodyClass = ''
  export let modalDialogClass = ''
  export let modalBodyFlat = false
  export let modalContent = ''
  export let modalContentElement: HTMLElement | undefined = undefined
  export let loading = false
  export let maxWidth: number | null = null
  // Родительская модалка
  export let modalParent: HTMLElement | null = null
  export let modalOptions: undefined | { backdrop?: boolean | 'static'; keyboard?: boolean; focus?: boolean } =
    undefined

  let modalClass = ''
  export { modalClass as class }

  // Ересь. Меня заставили.
  let modalParentsCount = 0 // отдельная переменная для реактивности
  const modalParents: HTMLElement[] = []
  let modalParentsCheckComplete = false

  const dispatch = createEventDispatcher<{
    hidden: null
    triggerClick: { trigger: HTMLElement | null }
  }>()

  const processTriggerClick = (source: MouseEvent | HTMLElement) => {
    const eventTarget = source instanceof MouseEvent ? (source.target as HTMLElement) : source
    dispatch('triggerClick', { trigger: eventTarget.closest('.btn') })
    show = true

    if (!modalParentsCheckComplete) {
      // Шатаемся по всем родителям кнопки, ищем модальные окна
      let currentParent = eventTarget.parentElement
      while (currentParent) {
        if (currentParent.classList.contains('modal')) {
          modalParentsCount++
          modalParents.push(currentParent)
          currentParent.classList.add('has-children')
        }

        currentParent = currentParent.parentElement
      }

      modalParentsCheckComplete = true
    }
  }

  const initBsModal = () => {
    if (bsModal) {
      // already initialized
      return
    }

    if (modalElement) {
      document.body.appendChild(modalElement)
      bsModal = new Modal(modalElement, modalOptions)
      if (show) {
        bsModal.show()
      }

      // Пока поддерживается только одна степень вложенности
      if (modalParent) {
        modalParentsCheckComplete = true
        modalParents.push(modalParent)
        modalParentsCount = 1
        modalParent.classList.add('has-children')
      }

      modalElement.addEventListener('show.bs.modal', () => {
        show = true
        delayedShow = true
      })

      modalElement.addEventListener('hide.bs.modal', () => {
        show = false
      })

      modalElement.addEventListener('hidden.bs.modal', () => {
        delayedShow = false

        if (conditional) {
          destroyBsModal()
        }

        dispatch('hidden')
      })

      addModalDrag(modalElement)
    }

    if (trigger instanceof HTMLElement) {
      // Если окно показывается сразу, обрабатываем иерархию
      if (show) {
        processTriggerClick(trigger)
      }

      trigger.addEventListener('click', processTriggerClick, { passive: true })
    } else if (trigger instanceof NodeList) {
      for (const subTrigger of trigger) {
        subTrigger.addEventListener('click', processTriggerClick, { passive: true })
      }
    }
  }

  // eslint-disable-next-line func-style
  function destroyBsModal() {
    if (bsModal) {
      try {
        bsModal.hide()
        bsModal.dispose()
      } catch {
        /* Просто игнорируем, окно могло быть удалено раньше */
      }
    }

    if (modalElement) {
      removeModalDrag(modalElement)
    }

    if (trigger instanceof HTMLElement) {
      trigger.removeEventListener('click', processTriggerClick)
    } else if (trigger instanceof NodeList) {
      for (const subTrigger of trigger) {
        subTrigger.removeEventListener('click', processTriggerClick)
      }
    }

    bsModal = null
  }

  onMount(() => {
    if (!conditional || (conditional && delayedShow)) {
      initBsModal()
    }

    return () => {
      destroyBsModal()
    }
  })

  const onShowHide = async (localShow: boolean) => {
    if (localShow) {
      delayedShow = true
      await tick()

      if (conditional && !bsModal) {
        initBsModal()
      }

      bsModal?.show()
    } else {
      modalElement?.blur()
      bsModal?.hide()

      if (modalParentsCount > 0) {
        modalParents[modalParentsCount - 1].classList.remove('has-children')
      }

      onHideExport()
    }
  }

  // Срабатывает до onMount, поэтому надо проверять и наличие bsModal
  $: void onShowHide(show)
</script>

{#if !conditional || (conditional && delayedShow)}
  <aside
    bind:this={modalElement}
    class="modal fade {id} {modalClass}{modalParentsCount > 0 ? ' modal--level-' + (modalParentsCount + 1) : ''}"
    {id}
    tabindex="-1"
    aria-labelledby="{id}__title"
    aria-hidden={!show}
  >
    <div
      class="modal-dialog modal-dialog-centered {'modal-' + size} {modalDialogClass}"
      style:max-width={maxWidth ? maxWidth + 'px' : null}
    >
      <div
        class="modal-content"
        bind:this={modalContentElement}
      >
        <slot name="misc" />
        <div class="modal-mobile-header">
          <button
            type="button"
            class="btn btn-link btn--with-icon"
            data-bs-dismiss="modal"
          >
            <svg
              class="btn__icon"
              width="13"
              height="13"
              aria-hidden="true"
            >
              <use xlink:href="{sprites.svgsprite2}#controls--arrow-thin-left"></use>
            </svg>
            <span>Назад</span>
          </button>
        </div>
        <header class="modal-header">
          <slot name="title">
            {#if title}
              <p
                class="modal-title h2 fs-24p text--half-border"
                id="{id}__title"
              >
                {title}
              </p>
            {/if}
          </slot>
          <button
            type="button"
            class="btn-close btn-close--white"
            data-bs-dismiss="modal"
            aria-label="Закрыть"
          ></button>
        </header>
        <div
          class="modal-body {bodyClass}"
          class:modal-body--flat={modalBodyFlat}
          class:loading
        >
          {#if loading}
            <Loader class="loader" />
          {/if}
          <slot>{@html modalContent}</slot>
        </div>
        {#if $$slots.footer}
          <div class="modal-footer">
            <slot name="footer" />
          </div>
        {/if}
      </div>
    </div>
  </aside>
{/if}
