import { sprites } from '@js/includes/_generatedVariables.ts'

const generateButtonContent = (icon: string, textContent: string) => {
  return `<svg class="btn-order__icon" width="24" height="24" aria-hidden="true">
            <use xlink:href="${sprites.svgsprite2}#${icon}"></use>
          </svg>
          <span class="btn-order__text">${textContent}</span>`
}

export const updateOrderButton = (orderButton: HTMLLinkElement, parameters: GenerateOrderButtonParameters) => {
  if (parameters.productID) {
    orderButton.dataset.productId = parameters.productID
  }

  if (parameters.inCart) {
    orderButton.href = '/cart/'
    orderButton.classList.remove('btn-primary')
    orderButton.classList.add('btn-success', 'btn-order--to-cart')
    orderButton.innerHTML = generateButtonContent(parameters.icon ?? 'icon_order', parameters.text ?? 'Уже в корзине')
  } else {
    orderButton.href = '/cart/'
    orderButton.classList.add('btn-primary')
    orderButton.classList.remove('btn-success', 'btn-order--to-cart')
    orderButton.innerHTML = generateButtonContent(parameters.icon ?? 'cart_ok', parameters.text ?? 'Купить')
  }
}

export const generateOrderButton = (parameters: GenerateOrderButtonParameters = {}) => {
  const localParameters = {
    inCart: false,
    ...parameters,
  }

  if (localParameters.inCart) {
    return `<a href="/cart/" class="btn btn-lg btn-success btn-order btn-order--to-cart ${localParameters.class ?? ''}"
        ${localParameters.productID ? ' data-product-id="' + localParameters.productID + '"' : ''}>
        ${generateButtonContent(localParameters.icon ?? 'icon_order', localParameters.text ?? 'Перейти в корзину')}
      </a>`
  } else {
    return `<a href="/cart/" role="button" class="btn btn-lg btn-primary btn-order ${localParameters.class ?? ''}"
          ${localParameters.productID ? ' data-product-id="' + localParameters.productID + '"' : ''}>
          ${generateButtonContent(localParameters.icon ?? 'cart_ok', localParameters.text ?? 'Купить')}
        </a>`
  }
}

type GenerateOrderButtonParameters = {
  inCart?: boolean | undefined
  class?: string | undefined
  productID?: string | undefined
  text?: string | undefined
  icon?: string | undefined
}
