import type AddedToCart from '@js/components/modals/added-to-cart/AddedToCart.svelte'
import type { ComponentProps } from 'svelte'
import { createClassComponent } from 'svelte/legacy'

let addedToCartModal: AddedToCart | undefined

export const showAddToCartModal = async (props: ComponentProps<AddedToCart>) => {
  const { default: AddedToCart } = await import('@js/components/modals/added-to-cart/AddedToCart.svelte')
  if (!addedToCartModal) {
    addedToCartModal = createClassComponent({
      component: AddedToCart,
      target: document.body,
      props: {
        // TODO: Баг https://github.com/sveltejs/svelte/issues/14161
        onHideExport: () => {
          addedToCartModal?.$set({
            show: false,
            productID: undefined,
            optionIDs: new Set(),
            amount: undefined,
            areaStep: undefined,
            multiple: undefined,
          })
        },
      },
    })
  }

  addedToCartModal.$set({
    ...props,
    show: true,
    productID: import.meta.env.DEV ? '987271' : props.productID,
    optionIDs: import.meta.env.DEV ? new Set(['255321']) : (props.optionIDs ?? new Set()),
  })
}
