import { type Writable, writable } from 'svelte/store'

let activeCard: HTMLElement | null = null
const activeCanvasImage: Writable<HTMLImageElement | null> = writable(null)
let imageClone: HTMLImageElement | null = null
let initialDistance = 0
let initialCenter = [0, 0]

const blockBodyTouchMove = (event: TouchEvent) => {
  event.preventDefault()
  return false
}

const calculateDistance = (touch1: Touch, touch2: Touch) => {
  const dx = touch1.clientX - touch2.clientX
  const dy = touch1.clientY - touch2.clientY
  return Math.hypot(dx, dy)
}

const getCenter = (touch1: Touch, touch2: Touch) => {
  return [(touch1.clientX + touch2.clientX) / 2, (touch1.clientY + touch2.clientY) / 2]
}

const onTouchMove = (event: TouchEvent) => {
  if (event.touches.length === 2) {
    const touch1 = event.touches[0]
    const touch2 = event.touches[1]
    const currentDistance = calculateDistance(touch1, touch2)

    let scale = currentDistance / initialDistance

    if (scale > 3) {
      scale = 3
    } else if (scale < 1) {
      scale = 1
    }

    const center = getCenter(touch1, touch2)
    const midX = (center[0] - initialCenter[0]) / scale
    const midY = (center[1] - initialCenter[1]) / scale

    requestAnimationFrame(() => {
      if (imageClone) {
        imageClone.style.transform = `scale(${scale}) translate(${midX}px, ${midY}px)`
      }
    })
  }
}

const removeZoom = () => {
  document.body.removeEventListener('touchmove', blockBodyTouchMove)

  if (imageClone) {
    imageClone.remove()
  }

  activeCard?.classList.remove('is-zooming-in')
  activeCanvasImage.set(null)
  activeCard = null
}

const onTouchEnd = () => {
  if (!imageClone) {
    return
  }

  imageClone.classList.remove('is-zooming')

  requestAnimationFrame(() => {
    if (imageClone) {
      imageClone.addEventListener('transitionend', removeZoom, { once: true })

      imageClone.style.transform = 'scale(1.0) translate(0, 0)'
    }
  })

  // Если не было transition
  setTimeout(() => {
    if (imageClone) {
      removeZoom()
    }
  }, 550)
}

export const initProductCardZoom = () => {
  activeCanvasImage.subscribe((currentActiveImage) => {
    if (!currentActiveImage) {
      return
    }

    if (imageClone) {
      imageClone.remove()
    }

    imageClone = currentActiveImage.cloneNode() as HTMLImageElement
    const activeImageBoundingRect = currentActiveImage.getBoundingClientRect()
    imageClone.className = 'product-card__zoomed-image is-zooming'
    imageClone.setAttribute('style', `top: ${activeImageBoundingRect.top}px; left: ${activeImageBoundingRect.left}px;`)
    imageClone.setAttribute('loading', 'eager')
    imageClone.setAttribute('sizes', 'auto')
    imageClone.setAttribute('width', activeImageBoundingRect.width + 'px')
    imageClone.setAttribute('height', activeImageBoundingRect.height + 'px')

    imageClone.addEventListener('touchend', onTouchEnd, { passive: true })
    currentActiveImage.addEventListener('touchend', onTouchEnd, { passive: true })
    imageClone.addEventListener('touchcancel', onTouchEnd, { passive: true })
    currentActiveImage.addEventListener('touchcancel', onTouchEnd, { passive: true })
    imageClone.addEventListener('touchmove', onTouchMove, { passive: true })
    currentActiveImage.addEventListener('touchmove', onTouchMove, { passive: true })

    document.body.appendChild(imageClone)
  })

  // const cardImages = card.querySelectorAll<HTMLImageElement>('img.product-card__image')
  // for (const cardImage of cardImages) {
  document.addEventListener(
    'touchstart',

    (event: TouchEvent) => {
      if (event.touches.length === 2) {
        const target = event.target as HTMLImageElement
        if (target.matches('img.product-card__image')) {
          initialDistance = calculateDistance(event.touches[0], event.touches[1])
          initialCenter = getCenter(event.touches[0], event.touches[1])

          activeCard = target.closest('.product-card')
          activeCard?.classList.add('is-zooming-in')
          activeCanvasImage.set(target)
          document.body.addEventListener('touchmove', blockBodyTouchMove, { passive: false })
        }
      }
    },
    { passive: true },
  )
}
